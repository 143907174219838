@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300&display=swap");

@tailwind base;
html {
  scroll-behavior: smooth;
}

@tailwind components;
@tailwind utilities;

p {
  @apply text-sm;
}

span {
  @apply text-xs;
}

h1 {
  @apply text-xl font-bold;
}

.project-image {
  @apply absolute h-full w-full object-cover object-top;
}

.project-container {
  @apply relative shadow-sm h-52 mb-4 rounded-lg overflow-hidden;
}

.project-link {
  @apply absolute flex items-center justify-center text-white text-xs w-full h-full font-semibold bg-slate-600/40 opacity-0 transition-opacity duration-300 group-hover:opacity-100;
}

.cert-hover {
  @apply absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-40 transition duration-300 ease-in-out bg-white text-black;
}

.cert-img {
  @apply border-solid border-2 border-gray-400 absolute w-full h-full object-cover rounded-lg;
}

.cert-container {
  @apply relative pb-3/4;
}
